.Container-habilities {
    background: var(--dark2);
    padding: 0 80px;
}

.Habilities {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
    gap: 48px;
    position: relative;
}

.Tag {
    font-size: 1rem;
    font-family: 'Space Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid #434343;
    background: var(--dark-3, #1e1d1d);
    color: var(--red2);
}

.Title {
    text-align: center;
}

.Title p {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.1em;
    text-transform: lowercase;
    color: var(--red2);
}

.Title h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    color: var(--gray3);
}

.Cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 48px;
    width: 100%;
}

.Card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--dark3);
    padding: 60px 60px;
    gap: 36px;
    text-align: center;
    border-radius: 8px;
    transition: All 0.3s;
    animation: carddown 0.3s forwards;
    flex: 1 0 0;
    min-width: 250px;
}

.Card:hover {
    background: var(--red2);
    box-shadow: var(--shadow-red);
    animation: cardup 0.3s forwards;
}

.Card div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.Card img {
    transition: All 0.3s;
}

.Card:hover img {
    filter: invert(98%) sepia(9%) saturate(26%) hue-rotate(245deg)
        brightness(111%) contrast(95%);
}

.Card h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    color: var(--white);
    width: 160px;
}

.Card p {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: var(--white);
    max-width: 100%;
}

.Arrow-down {
    position: absolute;
    top: calc(0% - 36px / 2);
    animation: arrowupdown 4s infinite ease-in-out;
}

@keyframes arrowupdown {
    from {
        transform: translateY(-4px);
    }

    50% {
        transform: translateY(4px);
    }

    to {
        transform: translateY(-4px);
    }
}

@keyframes cardup {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-16px);
    }
}

@keyframes carddown {
    from {
        transform: translateY(-16px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 768px) {
    .Container-habilities {
        padding: 0 0;
    }
    .Habilities {
        padding: 80px 24px;
    }

    .Cards {
        gap: 32px;
    }

    .Card {
        width: 100%;
    }

    .Logos-slide img {
        height: 36px;
        margin: 0 36px;
    }
}
