.Footer {
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0 0 0;
    gap: 32px;
    background: var(--red2);
}

.Footer p {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    color: var(--gray2);
    text-align: center;
    padding: 0 24px;
}

.WhiteButton {
    font-family: 'Fira Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 24px;
    gap: 12px;
    border: 2px solid var(--red2);
    border-radius: 8px;
    background: var(--white);
    color: var(--red2);
    transition: Background 0.3s;
    font-weight: 600;
    font-size: 12px;
    line-height: 200%;
}

.WhiteButton:hover {
    background: var(--red1);
}

.Footer div {
    display: flex;
    margin-top: 32px;
}

.Footer div img {
    transition: All 0.3s;
    border: 8px solid transparent;
    border-radius: 100%;
}

.Footer div img:hover {
    display: flex;
    background: var(--red1);
    border: 8px solid var(--red1);
    cursor: pointer;
}

.Footer div:nth-last-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--white);
    padding: 32px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: var(--red1);
}

.link-logos {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1000px) {
}
