.Apresentation {
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 80px 120px;
    gap: 32px;
    background: url(../images/bg-banner.svg) no-repeat center;
    background-position-y: 25rem;
}

.Apresentation div {
    display: flex;
    align-items: center;
    gap: 24px;
}

.Apresentation div img {
    width: 80px;
    border: 2px solid var(--gray2);
    border-radius: 100%;
}

.Apresentation p {
    color: var(--gray3);
    font-weight: 400;
    font-size: 12px;
}

.Apresentation h1 {
    color: var(--dark300);
    font-weight: 700;
    font-size: 56px;
    text-align: center;
    max-width: 900px;
}

.Apresentation span {
    color: var(--white);
}

.GradientButton {
    border: none;
    font-family: 'Fira Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    gap: 8px;
    border-radius: 8px;
    background: transparent;
    color: var(--white);
    transition: Background 0.2s;
    font-size: 12px;
    line-height: 200%;
    animation: slideof 0.2s forwards;
    border-radius: 0.5rem;
    background: var(--red-gradient-2);
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
}

.GradientButton:hover {
    border: none;
    background: var(--red2);
    color: var(--gray2);
    cursor: pointer;
    animation: slidein 0.2s forwards;
}

@media screen and (max-width: 1000px) {
    .Apresentation {
        padding: 200px 24px 120px;
    }
    .Apresentation h1 {
        font-size: 32px;
    }
}
