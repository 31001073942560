@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

:root {
    --red1: #40010c;
    --red2: #8c041c;
    --red-gradient: linear-gradient(90deg, #40010c 0%, #8c041c 100%);
    --red-gradient-2: linear-gradient(90deg, #b90021 0%, #ff1942 100%);
    --dark1: #0a0a0a;
    --dark2: #111111;
    --dark3: #1e1d1d;
    --dark300: #a4a4a4;
    --dark4: #818181;
    --gray1: #d3cbcb;
    --gray2: #e3d8d8;
    --gray3: #efe5e5;
    --white: #ffeff2;
    --shadow: 4px 4px 24px rgba(239, 229, 229, 0.15);
    --shadow-red: 4px 4px 24px rgba(140, 4, 28, 0.25);
}

body {
    margin: 0;
    font-family: 'Fira Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
    color: var(--white);
}

body::-webkit-scrollbar {
    width: 12px;
}

body::-webkit-scrollbar-track {
    background: var(--white);
}

body::-webkit-scrollbar-thumb {
    background-color: var(--red2);
    border-radius: 20px;
    border: 3px solid var(--white);
}
