.Header {
    width: 100%;
    position: fixed;
    z-index: 999;
    background-color: var(--dark1);
    height: 80px;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #111111;
}

.Header div {
    min-width: 1200px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.Container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Logo {
    position: absolute;
    left: 0;
    top: calc(50% - 32px / 2);
}

.Header ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    position: absolute;
    right: calc(50% - 563px / 2);
    top: calc(50% - 80px / 2);
}

.Header li {
    display: flex;
    align-items: center;
}

.Header li a {
    font-size: 14px;
    font-weight: 500;
    padding: 31px 24px;
    color: var(--dark4);
    border-color: transparent;
    transition: all 0.3s;
}

.Header li a:hover {
    cursor: pointer;
    color: var(--white);
    border-bottom: 1px solid var(--red2);
}

.RedButton {
    font-family: 'Fira Sans', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    border: 2px solid var(--red2);
    border-radius: 8px;
    background: transparent;
    color: var(--white);
    transition: Background 0.2s;
    font-size: 12px;
    font-weight: 500;
    line-height: 200%;
    position: absolute;
    right: 0;
    top: calc(50% - 52px / 2);
    animation: slideof 0.2s forwards;
}

button:hover {
    background: var(--red2);
    border: 2px solid var(--red2);
    color: var(--gray2);
    cursor: pointer;
    animation: slidein 0.2s forwards;
}

@keyframes slidein {
    from {
        gap: 8;
    }
    to {
        gap: 24px;
    }
}

@keyframes slideof {
    from {
        gap: 24px;
    }
    to {
        gap: 8px;
    }
}

@media screen and (max-width: 1000px) {
    .Logo {
        position: initial;
    }

    .Header div {
        min-width: 100%;
        align-items: center;
        justify-content: center;
    }

    .Header ul {
        display: none;
    }

    .RedButton {
        display: none;
    }
}