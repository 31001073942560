.Galery {
    width: 100%;
    /* max-width: 1410px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 0px;
}

.Inner {
    display: flex;
}

.Item {
    padding: 0 16px;
    min-width: 300px;
}

.Item img {
    width: 100%;
    border-radius: 8px;
    pointer-events: none;
    filter: grayscale(100%);
    transition: all 0.8s;
}

.Item:hover img {
    filter: grayscale(0%);
}

.Carousel {
    cursor: grab;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .Galery {
        padding: 0px 24px 80px;
    }
}
